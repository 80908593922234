export const Bio = {
  name: "Ameya Shahu",
  roles: [
    "Software Engineer"
  ],
  description:
    "Pursuing a Master's degree in Computer Science at Arizona State University. With a fervent passion for exploring various domains including Gen AI, software development, IoT, ML and Deep Learning. Thriving on the challenges and opportunities brought forth by cutting-edge technologies, I am driven to innovate and create impactful solutions.",
  github: "https://github.com/ameya-shahu",
  resume:
    "https://drive.google.com/file/d/1ffZrcMcn8UatXGIaautbbqpV7ADNaETA/view?usp=sharing",
  linkedin: "https://www.linkedin.com/in/ameya-shahu",
  insta: "https://www.instagram.com/ameya_shahu",
  medium: "https://medium.com/@ameyashahu",
  googleScholar: "https://scholar.google.com/citations?user=CFQMGOsAAAAJ&hl=en",
  email: "mailto:ameyashahu@gmail.com"
};

export const skills = [
  {
    title: "Backend",
    skills: [
      {
        name: "Mulesoft Anypoint Platform",
        image: "https://wp.salesforce.com/en-us/wp-content/uploads/sites/4/2023/11/mulesoft-logo.webp?w=528",
      },
      {
        name: "Node.js",
        image:
          "https://cdn-icons-png.flaticon.com/512/919/919825.png",
      },
      {
        name: "Express",
        image: "https://banner2.cleanpng.com/20180711/yfe/kisspng-express-js-node-js-javascript-mongodb-node-js-5b461d28173fc6.1251392115313216400952.jpg",
      },
      {
        name: "Spring Boot",
        image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRqyq-9lQQNZE9gQKIw_Vgl3_tBEmypLFSGiZQFCxO97g&s",
      },
      {
        name: "Flask",
        image: "https://www.svgrepo.com/show/473611/flask.svg",
      },
      {
        name: "Java",
        image: "https://cdn.iconscout.com/icon/free/png-256/free-java-2038875-1720088.png?f=webp",
      },
      {
        name: "Python",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/python/python-original.svg",
      }
    ],
  },
  {
    title: "Databases",
    skills: [
      {
        name: "MySQL",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original-wordmark.svg",
      },
      {
        name: "PostgreSQL",
        image: "https://www.postgresql.org/media/img/about/press/elephant.png",
      },
      {
        name: "MongoDB",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/mongodb/mongodb-original-wordmark.svg",
      },
      {
        name: "Firebase",
        image: "https://www.vectorlogo.zone/logos/firebase/firebase-icon.svg",
      }
    ]
  },
  {
    title: "Frontend",
    skills: [
      {
        name: "React.js",
        image:
          "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0xMS41IC0xMC4yMzE3NCAyMyAyMC40NjM0OCI+CiAgPHRpdGxlPlJlYWN0IExvZ288L3RpdGxlPgogIDxjaXJjbGUgY3g9IjAiIGN5PSIwIiByPSIyLjA1IiBmaWxsPSIjNjFkYWZiIi8+CiAgPGcgc3Ryb2tlPSIjNjFkYWZiIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIi8+CiAgICA8ZWxsaXBzZSByeD0iMTEiIHJ5PSI0LjIiIHRyYW5zZm9ybT0icm90YXRlKDYwKSIvPgogICAgPGVsbGlwc2Ugcng9IjExIiByeT0iNC4yIiB0cmFuc2Zvcm09InJvdGF0ZSgxMjApIi8+CiAgPC9nPgo8L3N2Zz4K",
      },
      {
        name: "Redux",
        image:
          "https://d33wubrfki0l68.cloudfront.net/0834d0215db51e91525a25acf97433051f280f2f/c30f5/img/redux.svg",
      },
      {
        name: "HTML",
        image: "https://www.w3.org/html/logo/badge/html5-badge-h-solo.png",
      },
      {
        name: "CSS",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/CSS3_logo_and_wordmark.svg/1452px-CSS3_logo_and_wordmark.svg.png",
      },
      {
        name: "JavaScript",
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/JavaScript-logo.png/800px-JavaScript-logo.png",
      },
      {
        name: "Bootstrap",
        image:
          "https://getbootstrap.com/docs/5.3/assets/brand/bootstrap-logo-shadow.png",
      },
      {
        name: "TailwindCSS",
        image:
          "https://avatars.githubusercontent.com/u/30317862?s=280&v=4",
      }
    ],
  },
  {
    title: "Others",
    skills: [
      {
        name: "Git",
        image:
          "https://avatars.githubusercontent.com/u/18133?s=280&v=4",
      },
      {
        name: "Docker",
        image:
          "https://raw.githubusercontent.com/devicons/devicon/master/icons/docker/docker-original-wordmark.svg",
      },
      {
        name: "AWS",
        image:
          "https://www.pngall.com/wp-content/uploads/13/AWS-Logo-PNG-File.png",
      },
      {
        name: "RabbitMQ",
        image:
          "https://static-00.iconduck.com/assets.00/rabbitmq-icon-242x256-0y9dqnak.png",
      }
    ],
  },
];

export const experiences = [
  {
    id: 0,
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzSKpRGUxJg9xFHJBWoeh4D16hWpDMVipqEvnAZUDwQjl6t23o5Y671XbcdH8eYfhGPCk&usqp=CAU",
    role: "Teaching Assistant (CSE 564 Software Design- Fall 2024)",
    company: "Arizona State University",
    date: "Aug 2024 - Present",
    desc: "Assisted students with requirements analysis, design patterns, and software architecture through assignments and review sessions for CSE 564 Software Design course.",
    skills: [
      "Software Design",
      "Software Architecture"
    ],
  },
  {
    id: 0,
    img: "https://media.licdn.com/dms/image/v2/D560BAQGyArUaiiY48Q/company-logo_200_200/company-logo_200_200/0/1714074380599/synapse_labs_logo?e=1732752000&v=beta&t=qXJoFUlmjk00GwjONrvrppLVx8BXr4A85dXjHpk1d94",
    role: "Software Engineer Intern",
    company: "Synapse Labs",
    date: "July 2024 - Present",
    desc: "Engineered a Python SDK for metadata generation, profiled datasets with Apache Spark, enhancing data quality, and integrated OpenAI API with News API for relevant news extraction.",
    skills: [
      "REST API",
      "Docker",
      "AWS",
      "Python",
      "GPT 4.0",
      "Apache Spark"
    ],
  },
  {
    id: 0,
    img: "https://media.licdn.com/dms/image/D4E0BAQHihSQL36mszQ/company-logo_200_200/0/1696261001196/apisero_logo?e=2147483647&v=beta&t=6BEYTjpqf3sWgI662OyZl-GvT5R9ynUnkI96PdC37ys",
    role: "Senior Software Engineer",
    company: "Apisero Inc. (An NTT DATA Company)",
    date: "May 2021 - July 2023",
    desc: "Transformed cross-platform integration and data management for diverse clients, achieving significant efficiency in enterprise softwares through expertise in MuleSoft.",
    skills: [
      "MuleSoft",
      "REST API",
      "Docker",
      "AWS",
      "RabbitMQ",
      "Salesforce",
      "Java",
      "Oracle",
      "MS SQL Server"
    ],
  },
  {
    id: 1,
    img: "https://devinfo.in/wp-content/uploads/job-manager-uploads/company_logo/2021/12/CSBC.jpg",
    role: "Software Intern",
    company: "Center for Social and Behavior Change, Delhi, India",
    date: "Oct 2020 - Dec 2020",
    desc: "Developed web application with oTree (Django) and MongoDB for psychometric surveys, enhancing survey capabilities and data collection, and implemented Selenium RPA for streamlined data synchronization between fitness bands and PostgreSQL.",
    skills: [
      "Django",
      "Python",
      "PostgreSQL",
      "Selenium",
      "HTML",
      "CSS",
      "Bootstrap"
    ],
  },
  {
    id: 2,
    img: "https://media.licdn.com/dms/image/C510BAQHhOmNSa2T4FQ/company-logo_200_200/0/1631427274850/solar_industries_india_ltd_logo?e=2147483647&v=beta&t=LRaLgyNqmlbsL1WI-pjb0_NPKiPv3PmRgyO2kk_Zd6o",
    role: "Software Intern",
    company: "Solar Industries, Nagpur, India",
    date: "March 2020 - May 2020",
    desc: "Developed a command layer using Node.js for remote control of DJI Tello Drone, and implemented an asynchronous Python layer for video stream processing, resulting in enhanced functionality and efficiency.",
    skills: [
      "Node.js",
      "Python",
      "DJI Tello SDK",
      "OpenCV",
      "YOLO",
    ],
  },
  {
    id: 3,
    img: "https://media.licdn.com/dms/image/C4D0BAQGCZ1F0R45qeQ/company-logo_200_200/0/1677943376004/blue_zone_systems_private_limited_logo?e=2147483647&v=beta&t=DlFV_Swh--ZFroj5iz66qz2fnFKeAiepEuXaEcZQzX4",
    role: "Software Intern",
    company: "Bluezone Systems, Mumbai, India",
    date: "December 2019 - January 2020",
    desc: "Created a CRM chatbot using Google Dialogflow, empowered by a Flask API for enhanced response accuracy by 80%, revolutionizing customer interactions and support experiences.",
    skills: [
      "Google Cloud",
      "Google Dialogflow",
      "Flask",
      "Python",
      "JSON",
    ],
  },
];

export const education = [
  {
    id: 0,
    img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzSKpRGUxJg9xFHJBWoeh4D16hWpDMVipqEvnAZUDwQjl6t23o5Y671XbcdH8eYfhGPCk&usqp=CAU",
    school: "Arizona State University",
    date: "Aug 2023 - May 2025 (Expected)",
    grade: "3.89 GPA",
    desc: "Subjects - Advance Operating Systems, Statstical Machine Learning, Operationalize Deep Learning, Digital Video Processing, Software Design, Knowledge Representation",
    degree: "Master of Science in Computer Science",
  },
  {
    id: 1,
    img: "https://www.gcoen.ac.in/assets/images/gcoen.jpg",
    school: "Government College of Engineering, Nagpur",
    date: "Aug 2017 - Aug 2021",
    grade: "9.87 CGPA",
    desc: "Subjects - Operating Systems, Data Structure and Algorithm, Computer Architecture and Organization, Computer Networks, Compiler Design, Database Management Systems, Digital Circuits, Applied Mathematics",
    degree: "Bachelor's of Engineering (Computer Science)",
  },
];

export const projects = [
  {
    id: 0,
    title: "xv6 Operating System Enhancement",
    date: "Fall 2023",
    description:
      "The Xv6 OS enhancement project brings a suite of improvements to elevate system performance and resource utilization. By refining the boot process to load the bootloader after the initial boot ROM and kernel into main memory, the project ensures a seamless transition to kernel execution, optimizing system resource utilization. Implementing copy-on-write memory management minimizes duplication and maximizes resource utilization by dynamically sharing memory pages among parent and child processes until a write operation occurs. Additionally, the development of a user-level threading library enables efficient thread management within processes, facilitating smoother multitasking and concurrent processing. These enhancements collectively bolster the efficiency, versatility, and functionality of the Xv6 operating system, paving the way for further advancements in system optimization and innovation.",
    image: "projects/xv6.png",
    tags: [
      "Operating Systems", "QEMU", "xv6", "RISC-V", "C", "Assembly"
    ],
    category: "Systems Projects",
    github: "https://github.com/ameya-shahu/cse536-xv6-os-enhancement",
  },
  {
    id: 1,
    title: "HLS Server and Streaming Frontend",
    date: "Summer 2024",
    description:
      'An HLS streaming server built with Node.js and Express, paired with a React frontend for video uploading and streaming. Convert your videos into HLS format with multiple qualities and adaptive streaming capabilities. Powered by ffmpeg for video processing.',
    image:
      "projects/streaming.png",
    tags: ["React.js", "Node.js", "Express.js", "FFmpeg", "Video.js"],
    category: "Web Application",
    github: "hhttps://github.com/ameya-shahu/HLS-Streaming-Server-React",
    webapp: "https://hls-streaming-server-react.vercel.app/",
  },
  {
    id: 2,
    title: "Probability Perception Reporting Application",
    date: "April 2021 - Sep 2021",
    description:
      'This application aims to simulate the discrete adjustment experiment described in the paper titled "A Discrete Adjustment Experiment on Reporting Probabilities", allowing users to participate in a study of probability perception and reporting. Participants will be presented with scenarios and asked to provide their probability estimates for certain events.',
    image:
      "projects/ppsa.png",
    tags: ["React.js", "MongoDb", "Node.js", "Express.js", "Redux"],
    category: "Web Application",
    github: "https://github.com/ameya-shahu/probability-perception-exp-app",
    webapp: "",
  },
  {
    id: 3,
    title: "MedicoAId",
    date: "Oct 2020 - May 2021",
    description:
      "This is a MERN stack application to assist and reduce the workload of the doctors by developing a real-time graphical user interface that can help in disease identification by using deep learning algorithms and also, provides a feature to monitor and analyze biological parameters such as Heart Rate and blood oxygen level of a patient through IoT.",
    image:
      "projects/medicoaid.png",
    tags: [
      "React.js",
      "MongoDB",
      "Node.js",
      "Express.js",
      "Tensorflow.js",
      "Redux",
      'Pytorch',
      "MQTT",
      'IoT',
      'ESP32',
      'MAX30100 Sensor',
          
    ],
    category: "Web Application",
    github: "https://github.com/ameya-shahu/medicoaid-app",
    webapp: "",
    Paper: "https://www.taylorfrancis.com/chapters/edit/10.1201/9781003203926-15/blood-oxygen-level-pulse-rate-monitoring-using-iot-cloud-based-data-storage-latesh-malik-ameya-shahu-sohan-rathod-pranay-kuthe-prachi-patil",
    youtube: "https://youtu.be/TJmkv_Qe9tY?feature=shared"
  },
  {
    id: 4,
    title: "Drone-based Encroachment Detection",
    date: "Fall 2023",
    description:
      "This project aims to demonstrate the feasibility of using drones equipped with object detection capabilities to detect encroachments in restricted areas. The system utilizes Webots for drone simulation, Python for backend logic, and the YOLO model for object detection.",
    image:
      "projects/dronedetection.png",
    tags: ["DJI Drone", "Webot Simulator", "Python", "Object detection"],
    category: "Systems Projects",
    github: "https://github.com/ameya-shahu/drone-encroachment-detection-poc",
    youtube: "https://youtu.be/ArMCFcpaHNA?feature=shared"
  },
  {
    id: 5,
    title: "Real-Time MongoDB Change Stream Monitoring",
    date: "April 2021 - Sep 2021",
    description:
      `In response to the need for real-time monitoring of survey data for a probability perception reporting tool, I developed a project centered around MongoDB change streams. Leveraging Node.js for its robust event-driven architecture, a web server was crafted to continuously monitor the database for any inserts or modifications.
      Upon detection of a change, the server promptly emits a socket event, enabling seamless transmission of updates to the frontend UI in real-time. This streamlined approach ensures that administrators remain informed of any alterations to the survey data, facilitating prompt analysis and decision-making within the probability perception reporting tool.`,
    image:
      "projects/mongostream.png",
    tags: ["MongoDB", "Node.js", "Socket.io"],
    category: "Web Application",
    github: "https://github.com/ameya-shahu/mongodb-change-monitoring-lbr-app",
  },
  {
    id: 6,
    title: "Metricity- Real-Time Monitoring of Electrical Appliances",
    date: "Aug 2019 - March 2020",
    description:
      "The project primarily aims to provide real-time monitoring of electrical appliances. This monitoring, besides delivering appliance-level analysis, will also cater to simultaneous overall consumption. The idea is to synchronize the smart electricity meter with appliances through a digital platform. The whole system will be bound by a Wi-Fi connected ad-hoc network supported by IoT architecture and deployed on a reliable cloud platform.",
    image:
      "projects/metricity.png",
    tags: ["React.js", "PostgreSQL" , "Flask", "Python", "IoT", "MQTT","ESP8266"],
    category: "Web Application",
    youtube: "https://youtu.be/SruOvL-1i-U?feature=shared",
    paper: "https://ijeebs.com/wp-content/uploads/2020/07/NCRAET-2020-V7-I2-050-1.pdf",
  },
];

export const TimeLineData = [
  { year: 2017, text: "Started my journey" },
  { year: 2018, text: "Worked as a freelance developer" },
  { year: 2019, text: "Founded JavaScript Mastery" },
  { year: 2020, text: "Shared my projects with the world" },
  { year: 2021, text: "Started my own platform" },
];
